// a11y-toggle
[data-a11y-toggle]:not([aria-controls]),
[aria-hidden="true"] {
  display: none;
}

/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.map,
.breadcrumb,
.collapsible {
  [aria-hidden="true"] {
    /* 1 */
    display: block; /* 2 */
    display: initial;
  }
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  &--laterals {
    overflow: visible;
    @media #{$medium-up} {
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background-image: linear-gradient(334deg, $grad-end, $grad-start);
      }
      &:before {
        left: -100%;
        background-image: none;
        border-left: 20px solid #000;
      }
      &:after {
        right: -100%;
      }
    }
  }
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
iframe {
  border: 0;
  width: 100%;
  min-height: rem-calc(350);
  @media #{$medium-up} {
    min-height: rem-calc(450);
  }
}
.view-header {
  display: flex;
  flex-direction: column-reverse;
  time {
    order: unset;
  }
}
.map {
  background-color: #ddd;
  height: rem-calc(440);
}
.map-legend {
  margin-top: $spacing;
  @media #{$large-up} {
    margin-top: $spacing * 2.75;
  }
  span {
    font-size: rem-calc(14);
    font-weight: normal;
    margin-right: $spacing;
    display: inline-block;
    &:before {
      content: "";
      font-size: 1rem;
      vertical-align: middle;
      margin-right: $spacing * 0.25;
    }
    &:first-child {
      &:before {
        @include sprite("pin-itemas");
      }
    }
    &:last-child {
      &:before {
        @include sprite("pin-adheridos");
      }
    }
  }
}
.back {
  @include hover;
  display: none;

  @media #{$medium-up} {
    display: inline-block;
    float: right;
    a {
      text-align: right;
    }
  }
  /*margin-top: $spacing;
  @media #{$large-up} {
    font-size: rem-calc(20);
  }*/
  /*&:before {
    content: "";
    display: inline-block;
    position: absolute;
    vertical-align: middle;
    margin-right: $spacing * 0.25;
    /*@include sprite("bt-volver");

    width: 24px;
    height: 14px;
  }*/
  a {
    text-decoration: none;
    padding-left: $spacing * 1.5;
    background: url("/media/img/fletxa-tornar.png") no-repeat;
    background-position: center left;
  }
}
.text-fitxa {
  p,
  ul {
    font-size: 1rem;
  }
  .lead {
    font-size: 1.25rem;
  }
}
.text-fitxa-lateral {
  p {
    font-size: 1.125rem;
  }
}
.modul-btns {
  font-size: rem-calc(24);
  text-align: center;
  font-weight: 700;
  li {
    margin-bottom: rem-calc(25);
  }
  a {
    border-radius: 50px;
    padding: $spacing * 1.5;
    &:hover {
      border-radius: 35px;
    }
  }
}
.modul-dest,
.modul-dest-gris {
  padding: $spacing * 1.5;
  h3 {
    text-transform: none;
    font-size: rem-calc(20);
    font-weight: 500;
    margin-bottom: rem-calc(10);
  }
  p {
    font-size: rem-calc(14);
    line-height: 22px;
    font-weight: 300;
  }
  .btn {
    margin-top: rem-calc(20);
    &:after {
      content: none;
    }
  }
}
.modul-dest-gris {
  background-image: none;
  background-color: #e9e9e9;
  h3,
  p,
  .btn {
    color: #000 !important;
  }
  .btn {
    background-color: #fff !important;
    border-color: #000;
  }
}
.modul-text p {
  font-size: rem-calc(14);
  line-height: 22px;
}
.contacto {
  margin-bottom: rem-calc(15);
  p {
    font-size: 1rem !important;
  }
}
.servicios {
  .back {
    margin-top: rem-calc(-20);
    margin-bottom: rem-calc(20);
  }
}
/*
#gmap {
  max-height: rem-calc(200);
}*/
.text-fitxa {
  h2 {
    margin-bottom: rem-calc(15);
  }
}
.circles-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    font-size: rem-calc(18);
    justify-content: center;
  }
  &__item {
    margin: 0 0 $spacing * 1.5;
    padding: 0 $column-gutter * 0.5;
    width: 100%;
    text-align: center;
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media #{$medium-up} {
      width: 33.33%;
    }
    > a {
      @include hover;
      display: block;
      color: #000;
    }
  }
  &__title {
    font-size: rem-calc(20);
    font-weight: 900;
    margin-bottom: 0;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
    a {
      text-decoration: none;
      color: #fff;
      padding: $spacing;
      margin: 0 auto $spacing * 0.75;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 50%;
      width: $spacing * 8;
      height: $spacing * 8;
      line-height: 1.3;
      @media #{$medium-up} {
        width: $spacing * 9;
        height: $spacing * 9;
      }
      &:hover,
      &:focus {
        background-color: #272727;
      }
    }
  }
  &__image {
    margin: 0 auto $spacing * 0.75;
    border-radius: 50%;
    width: $spacing * 8;
    height: $spacing * 8;
    max-width: none;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
    @media #{$medium-up} {
      width: $spacing * 9;
      height: $spacing * 9;
    }
  }
  &__subtitle {
    font-size: rem-calc(18);
    font-weight: bold;
    margin-bottom: $spacing * 0.25;
    line-height: 1.2;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
  }
  &__text {
    font-size: rem-calc(14);
    font-weight: 300;
    margin-bottom: 0;
    @media #{$medium-up} {
      font-size: rem-calc(16);
    }
  }
  a {
    &:hover,
    &:focus {
      img {
        opacity: 0.9;
      }
    }
  }
}
.section-divider {
  margin-bottom: rem-calc(40);
}
