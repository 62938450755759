html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.js body {
  @media #{$large-up} {
    padding-top: rem-calc(183); // headroom
  }
}

// Stack (https://every-layout.dev/layouts/stack/)
.stack-smaller > * + * {
  margin-top: $spacing * 0.25;
}
.stack-smaller-recursive * + * {
  margin-top: $spacing * 0.25;
}
.stack-small > * + * {
  margin-top: $spacing * 0.5;
}
.stack-small-recursive * + * {
  margin-top: $spacing * 0.5;
}
.stack > * + * {
  margin-top: $spacing;
}
.stack-recursive * + * {
  margin-top: $spacing;
}
.stack-large > * + * {
  margin-top: $spacing * 2;
}
.stack-large-recursive * + * {
  margin-top: $spacing * 2;
}
.stack-larger > * + * {
  margin-top: $spacing * 2.5;
}
.stack-larger-recursive * + * {
  margin-top: $spacing * 2.5;
}
.stack-largest > * + * {
  margin-top: $spacing * 3;
}
.stack-largest-recursive * + * {
  margin-top: $spacing * 3;
}

@media #{$medium-up} {
  .row[class*="stack"] {
    > * + * {
      margin-top: 0;
    }
  }
}

// text en columnes
.text-2-col {
  @media #{$medium-up} {
    column-count: 2;
  }
}

/*.text-2-col__dest {
  margin: $spacing * 3 0;
  @media #{$medium-up} {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
  }
  & p {
    @media #{$medium-up} {
      width: 50%;
      padding: 10px;
    }
  }
  & > p:first-child {
    margin-right: 20px;
  }
}

.text-3-col {
  @media #{$medium-up} {
    column-count: 2;
  }
  @media #{$large-up} {
    column-count: 3;
  }
}
*/
.text-2-col,
.text-3-col {
  @include stack;
  @media #{$medium-up} {
    column-gap: $spacing * 1.5;
  }
  @media #{$large-up} {
    column-gap: $spacing * 2;
  }
  @media #{$xlarge-up} {
    column-gap: $spacing * 3;
  }
}

// .top-heading + .row {
//   @media #{$small-only} {
//     margin-top: 0;
//   }
// }
