// HAMBURGER
// mòbil
.hamburger {
  cursor: default;
  /*margin-bottom: $spacing;*/
  /*background: #3d3d3b; */
  background-color: #fff;
  width: 60px;
  height: 60px;
  /* @media screen and (min-width: 40em) { */
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  /* } */
}
.hamburger.is-active {
  background-color: #3d3d3b;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::after,
.hamburger.is-active .hamburger-inner::before {
  background-color: #fff;
}
.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  background-color: #000;
}

//

#menu {
  @media screen and (max-width: 49.9375em) {
    margin-left: -$column-gutter * 0.5;
    margin-right: -$column-gutter * 0.5;
    padding: $spacing $column-gutter * 0.5 $spacing * 1.5;
    background: #3d3d3b;
    margin-top: -8px;
    margin-bottom: $spacing;
    a {
      color: white;
    }
    li {
      margin: 0;
    }
    .lang {
      margin-bottom: $spacing;
    }
    address {
      color: white;
      font-style: normal;
      font-size: rem-calc(14);
      margin: $spacing * 2 0;
    }
    .social {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        border: 0;
        margin: 0;
        padding: 0;
        &.first {
          margin-right: $spacing * 0.75;
        }
        &:before {
          display: none;
        }
      }
    }
    .sub-menu--1 {
      margin-top: $spacing * 2;
      font-family: $body-font-family;
      li {
        border: 0;
        margin: $spacing * 0.5 0;
        padding: 0;
        a {
          text-decoration: none;
        }
        &:before {
          display: none;
        }
      }
    }
  }
}

.navigation {
  text-align: center;
  /*margin-top: $spacing;*/
  margin: $spacing 0 $spacing * 2 0;
  @media #{$medium-up} {
    /*margin-bottom: $spacing * 2;*/
    text-align: right;
  }
  button {
    @media #{$medium-up} {
      display: none;
    }
  }
}
@media #{$medium-up} {
  #menu {
    display: block;
  }
}
