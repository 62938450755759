/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: #fff;
  /*color: #444;*/
  cursor: pointer;
  padding: $spacing 0;
  width: 100%;
  border: none;
  border-bottom: 1px #000 solid;
  text-align: left;
  outline: none;
  font-size: 18px;
  font-family: $font-family-serif;
  text-transform: uppercase;
  position: relative;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
/*.active,
.collapsible:hover {
  background-color: #ccc;
}*/

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0;
  margin: $spacing * 0.5 0;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  .llista-logos li {
    border: 0;
    font-family: $body-font-family;
    &:before {
      display: none;
    }
    div {
      border: 5px #f2f2f2 solid;
      padding: $spacing;
      height: 250px;
      text-align: center;
      /*position: relative;*/
      /*min-height: 250px;
      img {
        vertical-align: middle;
      }*/
      img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        /*max-width: 190px;*/
      }
    }
    h3 {
      text-transform: none;
      margin: $spacing * 0.5 0;
      font-size: rem-calc(12);
    }
    /*img {
      margin-bottom: $spacing * 0.75;
    }*/
    p {
      font-size: rem-calc(12);
    }
  }
}

.collapsible:after {
  background: url("/media/img/desplega-items.png") no-repeat;
  /*content: "\02795"; /* Unicode character for "plus" sign (+) */
  content: "";
  width: 42px;
  height: 42px;
  /*float: right;*/
  position: absolute;
  right: -62px;
  top: 15px;
}

.active:after {
  /*content: "\2796"; /* Unicode character for "minus" sign (-) */
  background: url("/media/img/ico-tancar.png") no-repeat;
}

.ells-ja-ens-coneixen .collapsible {
  width: 85%;
  margin-right: $spacing * 2;
  @media #{$medium-up} {
    width: 50%;
  }
}
