.main-nav {
  @include undolist;
  font-family: $font-family-serif-thin;
  letter-spacing: 0.4px;
  font-size: 0;
  /*display: inline-block;*/
  width: 70%;
  margin: 0 auto;
  /*margin-top: $spacing;*/
  @media #{$medium-up} {
    text-align: left;
    width: 100%;
  }
  > * + * {
    margin-top: $spacing * 0.75;
    @media #{$medium-up} {
      margin-top: 0;
    }
  }
  > li {
    // &.active a,
    // a[aria-current="page"] {
    // }
    padding: $spacing * 0.65 $spacing * 0.5;
    border: 0;
    font-size: rem-calc(16);
    border-bottom: 1px #636363 solid;
    &.last {
      border: 0;
    }
    @media #{$medium-up} {
      display: inline;
      padding-right: $spacing;
      border: 0;
    }
    &.first {
      @media #{$medium-up} {
        padding-left: 0;
      }
    }
    > a {
      padding-bottom: 6px;
      text-decoration: none;
      color: $body-font-color;
      display: block;
      @media #{$medium-up} {
        display: inline;
        border-bottom: 2px solid transparent;
        transition: border-bottom-color 0.15s ease-in-out;
        font-family: $font-family-serif;
      }
      &:hover,
      &[aria-current="page"],
      &.active {
        @media #{$medium-up} {
          border-bottom-color: $primary-color;
          border-bottom-width: 24px;
        }
      }
    }
  }
}

.main-footer .main-nav {
  text-align: center;
  margin-bottom: $spacing * 2;
}

// #main {
//   margin-top: $spacing * 1.5;
// }
