.llistat-serveis {
  li {
    padding: 0;
    margin: 0;
    border-bottom: 1px #000 solid;
    &:before {
      display: none;
    }
  }
  a {
    font-size: rem-calc(18);
    font-family: $font-family-serif;
    padding: $spacing 0;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    position: relative;
    :hover {
      text-decoration: underline;
    }
    &:after {
      content: "";
      position: absolute;
      background: url("/media/img/fletxa-quefem.png") no-repeat;
      width: 45px;
      height: 41px;
      right: -75px;
      top: 50%;
      transform: translateY(-50%);
      @media #{$medium-up} {
        right: -65px;
      }
    }
    &:hover {
      &:after {
        @media #{$medium-up} {
          right: -75px;
        }
      }
    }
  }

  &.llistat--1 {
    margin-right: $spacing * 4;
    margin-left: $spacing;
    @media #{$medium-up} {
      margin-left: 0;
      margin-right: $spacing * 4;
    }
  }
  &.llistat--2 {
    margin-right: $spacing * 4;
    margin-left: $spacing;
    @media #{$medium-up} {
      margin-left: $spacing * 1;
      margin-right: $spacing * 3;
    }
  }
}

.enllacos {
  @include undolist;
  li {
    font-family: $body-font-family;
    border: 0;
    position: relative;
    padding-top: $spacing * 1.5;
    font-size: rem-calc(14);
    a {
      text-decoration: none;
      /*background: url("/media/img/link-extern.png") no-repeat;
      padding-left: $spacing;*/
    }
    ul {
      margin-left: 0;
      li {
        padding-top: 0;
        &:after {
          display: none;
        }
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: $spacing * 0.5;
      left: 0;
      width: $spacing * 3;
      height: 1px;
      background: #000;
    }
  }
}
