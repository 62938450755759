@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(../../fonts/Roboto-Light.ttf) format('truetype'), url(../../fonts/Roboto-Light.woff) format('woff'), url(../../fonts/Roboto-Light.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Roboto.eot);
  src: local('Roboto'), local('Roboto-Regular'), url(../../fonts/Roboto.svg#Roboto) format('svg'), url(../../fonts/Roboto.eot?#iefix) format('embedded-opentype'), url(../../fonts/Roboto.ttf) format('truetype'), url(../../fonts/Roboto.woff) format('woff'), url(../../fonts/Roboto.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+000-5FF;
}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(../../fonts/Roboto-Medium.ttf) format('truetype'), url(../../fonts/Roboto-Medium.woff) format('woff'), url(../../fonts/Roboto-Medium.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(../../fonts/Roboto-Bold.ttf) format('truetype'), url(../../fonts/Roboto-Bold.woff) format('woff'), url(../../fonts/Roboto-Bold.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+000-5FF;
}

