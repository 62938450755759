.slider-container {
  padding: 0;
  overflow-y: hidden;
  @media #{$medium-up} {
    padding-right: $column-gutter * 0.5;
  }
}
.slider {
  margin-bottom: 0 !important;
  height: $slider-h-sm;
  overflow-y: hidden;
  background-color: #eee;
  @media #{$medium-up} {
    height: $slider-h-md;
  }
  @media #{$height} {
    height: $slider-h-lg;
  }
  .slick-slide > div {
    height: $slider-h-sm;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    > div {
      display: flex;
    }
  }
  &__img {
    background-size: cover;
    background-repeat: no-repeat;
    height: $slider-h-sm;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    &--overlay {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  &__caption {
    text-align: center;
    color: #fff;
    position: relative;
    margin: 0 $column-gutter/2;
    @media screen and (min-width: 40em) {
      width: 50%;
      // margin: auto;
    }
    @media #{$medium-up} {
      width: 70%;
    }
    > *:last-child {
      margin-bottom: 0;
    }
    h2 {
      font-size: rem-calc(25);
      margin-bottom: $spacing * 0.5;
      line-height: 1;
      font-weight: 700;
      @media #{$medium-up} {
        font-size: rem-calc(40);
        margin-bottom: $spacing;
      }
      @media #{$large-up} {
        font-size: rem-calc(50);
      }
      @media #{$xxlarge-up} {
        font-size: rem-calc(65);
      }
    }
    p {
      line-height: 1.2;
      font-weight: normal;
      a {
        color: #fff;
      }
    }
    h2,
    p {
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      a {
        text-decoration: none !important;
        color: #fff !important;
        background: transparent !important;
        -webkit-text-fill-color: inherit !important;
        text-underline-offset: 5px !important;
        text-decoration-color: #fff !important;
        text-decoration-thickness: 2px !important;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
    h2 {
      font-weight: 900;
    }
    p a {
      text-decoration: underline !important;
      &:hover {
        text-decoration: none !important;
      }
    }
  }
  .slick-dots {
    @include undolist;
    bottom: 0;
    text-align: center;
    padding: $spacing * 0.5 $spacing;
    @media #{$medium-up} {
      text-align: right;
    }
    li {
      margin: 0 $spacing * 0.5 0 0;
      position: relative;
      top: -5px;
      button {
        border: 5px solid white;
        border-radius: 50%;
        &:before {
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 4px;
          left: 4px;
          background-color: #fff;
        }
      }
      &.slick-active button::before {
        background-color: $primary-color;
      }
    }
  }
  .popup-youtube {
    display: inline-block;
    &:before {
      content: "";
      @include sprite("ico-play-movil");
      font-size: 1rem;
      opacity: 1;
      transition: opacity 0.15s ease-in-out;
      @media #{$medium-up} {
        font-size: 1.5rem;
      }
    }
    &:hover:before {
      opacity: 0.8;
    }
  }
}
