.title {
  padding: $spacing * 2 $spacing * 2 $spacing * 4 $spacing * 2;
  position: relative;
  background-image: linear-gradient(to bottom, #c1c1c1, #f2f2f2);
  margin-bottom: $spacing * 3;
  text-align: left;
  @media #{$medium-up} {
    padding: $spacing * 2 0;
    min-height: 448px;
    p {
      width: 45%;
      position: relative;
      z-index: 1;
    }
    &:before {
      position: absolute;
      width: 427px;
      height: 448px;
      top: 0;
      left: 0;
      content: "";
      /*background: url("/media/img/simbol-opteam-fons-esq.png");*/
      background: url("/media/img/simbol-fons-esquerra.png");
      opacity: 0.2;
    }
    &:after {
      position: absolute;
      width: 427px;
      height: 448px;
      top: 0;
      right: 0;
      content: "";
      background: url("/media/img/simbol-fons-dreta.png");
      /*background: url("/media/img/simbol-opteam-fons@1x.png"); */
      opacity: 0.2;
    }
    &.quisom,
    &.contacte {
      &:before {
        opacity: 0.6;
      }
      &:after {
        opacity: 0.6;
      }
    }
  }
  @media (min-width: 700px) {
    padding-bottom: $spacing * 5;
  }
  @media #{$medium-up} {
    padding-bottom: $spacing * 9;
  }
  @media (min-width: 1000px) {
    padding-bottom: $spacing * 3;
    margin-bottom: $spacing * 10;
  }
  @media (max-width: 1000px) {
    p {
      width: 100%;
    }
  }
  h1 {
    padding-left: 0;
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  img.deco {
    position: absolute;
    bottom: -$spacing * 6;
    z-index: 1;
    @media (min-width: 600px) {
      bottom: -$spacing * 7;
    }
    @media (min-width: 700px) {
      bottom: -$spacing * 8;
    }
    @media #{$medium-up} {
      bottom: -$spacing * 11;
    }
    @media (min-width: 1000px) {
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: $spacing * 0.5;
      text-align: center;
    }
  }

  .dest {
    text-align: left;
    padding: 0 0 $spacing 0;
    font-size: rem-calc(21);
    font-family: $font-family-serif-light;
    &:before,
    &:after {
      display: none;
    }
    strong,
    b {
      font-family: $font-family-serif;
    }
    @media #{$medium-up} {
      width: 75%;
    }
  }
  &.quisom {
    @media #{$medium-up} {
      p {
        width: 100%;
      }
    }
  }
  &.quefem {
    background-image: linear-gradient(to bottom, #646a6c, #a6b2b4);
    padding: $spacing * 2 $spacing * 2 $spacing * 11 $spacing * 2;
    h1,
    p.dest {
      color: #fff;
    }
    p.dest {
      width: 100%;
    }
    @media (min-width: 700px) {
      padding-bottom: $spacing * 10;
    }
    @media #{$medium-up} {
      padding-bottom: $spacing * 12;
      p.dest {
        font-size: rem-calc(26);
      }
    }
    @media (min-width: 1000px) {
      padding-bottom: $spacing * 6;
      margin-bottom: $spacing * 2;
    }
    .deco {
      position: absolute;
      bottom: -$spacing * 13;
      @media (min-width: 700px) {
        bottom: -$spacing * 13;
      }
      @media (min-width: 1000px) {
        top: -$spacing * 4;
        margin-left: auto;
        margin-right: 0;
        right: 0;
        text-align: right;
      }
    }
  }
  &.ensconeixen {
    background-image: linear-gradient(to bottom, #7a3229, #ca5444);
    padding: $spacing * 2 $spacing * 2 $spacing * 11 $spacing * 2;
    h1,
    p {
      color: white;
    }
    @media (min-width: 700px) {
      padding-bottom: $spacing * 10;
    }
    @media #{$medium-up} {
      padding-bottom: $spacing * 12;
      p.dest {
        font-size: rem-calc(26);
      }
    }
    @media (min-width: 1000px) {
      padding-bottom: $spacing * 5;
      margin-bottom: $spacing * 2;
    }
    .deco {
      position: absolute;
      bottom: -$spacing * 13;
      @media (min-width: 700px) {
        bottom: -$spacing * 13;
      }
      @media (min-width: 1000px) {
        top: -$spacing * 2;
        margin-left: auto;
        margin-right: 0;
        right: $spacing * 6;
        text-align: right;
      }
    }
  }

  &.title--blank {
    background: none;
    position: static;
    height: auto;
    margin: 0;
    padding: 0;
    &:after,
    &:before {
      display: none;
    }
    img {
      margin: $spacing 0 0 0;
      width: rem-calc(173);
      height: rem-calc(173);
      @media #{$medium-up} {
        margin: $spacing * 2 0 0 0;
        /*width: auto;
        height: auto;*/
      }
    }
    @media #{$medium-up} {
      min-height: auto;
    }
  }

  &.error404 {
    background: #000;
    color: #fff;
    padding: $spacing * 2;
    margin-bottom: $spacing * 2;
    h1 {
      text-transform: lowercase;
      font-family: $font-family-serif-light;
      text-align: center;
    }
    p {
      width: 100%;
      text-align: center;
    }
    @media #{$medium-up} {
      padding: $spacing * 5;
      &:before {
        width: 419px;
        height: 422px;
        content: "";
        background: url("/media/img/capsaleres/pict-error.png");
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        opacity: 1;
      }
      &:after {
        display: none;
      }
    }
    @media (min-width: 1000px) {
      margin-bottom: $spacing * 2;
    }
  }
  &.contacte {
    padding: $spacing * 2 $spacing * 2 $spacing * 11 $spacing * 2;
    @media #{$medium-up} {
      padding: $spacing * 2;
      p {
        width: 50%;
      }
    }
    @media (min-width: 1000px) {
      margin-bottom: 0;
    }
    .deco {
      position: absolute;
      bottom: -$spacing * 15;
      right: 0;
      @media #{$medium-up} {
        bottom: -$spacing * 8;
        right: 0;
      }
      @media (min-width: 1000px) {
        top: -$spacing * 5;
        margin-left: auto;
        margin-right: 0;
        right: $spacing * 6;
        text-align: right;
      }
    }
  }
} /* .title */

.error .btn {
  margin-top: $spacing * 2;
}

.serveis-list {
  padding: $spacing * 0.25;
  h2 {
    font-size: rem-calc(20);
    padding: $spacing * 0.5 0;
  }
  p {
    font-family: $font-family-sans-serif;
  }
  li {
    border: 0;
    padding: $spacing * 1.25;
    &:before {
      display: none;
    }
  }
}
