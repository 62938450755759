select {
  appearance: none;
  border: 1px $body-font-color solid;
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDE5IDExIj4KICAgIDxwYXRoIGQ9Ik0xNi43NjcgMEw5LjUgNi43OTkgMi4yMzIgMCAwIDIuMDkzIDkuNSAxMSAxOSAyLjA5M3oiLz4KPC9zdmc+Cg==");
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
}
.form-wrapper {
  background-color: #303030;
  padding: $spacing;
  @media #{$medium-up} {
    padding: $spacing * 1.5;
  }
  @media #{$large-up} {
    padding: $spacing * 2;
  }
}
/*.houdiniForm*/
form {
  &:not(.filters-form) {
    @include stack;
    fieldset {
      @include stack;
    }
  }
  .form-body {
    @include stack;
  }
  fieldset {
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    > div {
      // margin-bottom: $spacing * 0.75;
      &:last-child {
        margin-bottom: 0;
      }
      .columns {
        @media screen and (max-width: 49.9375em) {
          margin-top: $spacing;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  legend {
    font-weight: bold;
    // margin-bottom: $spacing;
  }
  label {
    display: block;
    margin-bottom: $spacing * 0.25;
    font-family: $font-family-serif;
    &.simple-label {
      text-transform: none;
      letter-spacing: 0;
      font-weight: 300;
      font-size: rem-calc(16);
    }
    &.error {
      text-transform: none;
      letter-spacing: 0;
      font-weight: normal;
      color: #d60000;
      margin-top: $spacing * 0.25;
      font-size: rem-calc(14);
    }
    .form-wrapper & {
      color: #fff;
    }
    .info {
      text-transform: none;
      font-weight: 300;
      letter-spacing: 0;
    }
  }
  .simple-label label {
    @extend .simple-label;
  }
  input[type="text"],
  input[type="tel"],
  input[type="url"],
  input[type="email"],
  input[type="password"],
  input[type="search"],
  textarea,
  select {
    font-family: $font-family-serif;
    border: 0;
    border-radius: 38px;
    color: #313131;
    font-weight: normal;
    background-color: #ececec;
    padding: $spacing * 0.25 $spacing * 0.75;
    width: 100%;
    height: rem-calc(52);
    box-shadow: none;
    transition: background-color 0.1s;
    &.error {
      border-color: #f00;
    }
    &:focus {
      background-color: #fefefe;
    }
  }
  textarea {
    height: $spacing * 10;
  }
  select {
    appearance: none;
    border: 1px $body-font-color solid;
    background-color: #fff;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDE5IDExIj4KICAgIDxwYXRoIGQ9Ik0xNi43NjcgMEw5LjUgNi43OTkgMi4yMzIgMCAwIDIuMDkzIDkuNSAxMSAxOSAyLjA5M3oiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat, repeat;
    background-position: right 1em top 50%, 0 0;
  }
  li {
    border: 0;
  }

  // checkboxes & radiobuttons (https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/)
  // CAL REVISAR I SIMPLIFICAR CODI
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"] {
      --active: #606f72;
      --active-inner: #fff;
      --focus: 0;
      --border: 0;
      --border-hover: 0;
      --background: #d8d8d8;
      --disabled: grey;
      --disabled-inner: grey;
    }
    input[type="radio"] {
      --active: #fff;
      --active-inner: #a7b2b4;
      --focus: 1px #000;
      --border: 1px #000;
      --border-hover: 0;
      --background: #d8d8d8;
      --disabled: grey;
      --disabled-inner: grey;
    }

    input[type="checkbox"],
    input[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none;

      height: $spacing;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      // cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      &:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.1s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }
      // &:disabled {
      //   --b: var(--disabled);
      //   cursor: not-allowed;
      //   opacity: 0.9;
      //   &:checked {
      //     --b: var(--disabled-inner);
      //     --bc: var(--border);
      //   }
      //   & + label {
      //     cursor: not-allowed;
      //   }
      // }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      width: $spacing;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 0.75;
      }
    }
    input[type="checkbox"] {
      border-radius: 2px;
      &:after {
        width: 7px;
        height: 11px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 8px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    input[type="radio"] {
      border-radius: 50%;
      &:after {
        /*width: 16px;
        height: 16px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 1;
        transform: scale(var(--s, 0.7));
        top: 4px;
        left: 4px;*/
      }
      &:checked {
        --s: 1;
        border: 1px #000 solid;
        &:after {
          transform: scale(var(--s, 0.7));
          left: 4px;
          top: 4px;
        }
      }
    }
  }
  input[type="checkbox"],
  input[type="radio"] {
    margin-right: $spacing * 0.25;
  }
}
/*input.date {
  background-image: url("img/ico-calendario.svg");
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-position: calc(100% - 4px) 50%;
}*/

// desplegable arxius WP
.widget_archive {
  select {
    font-family: $font-family-serif;
    border: 0;
    border-radius: 38px;
    color: #313131;
    font-weight: normal;
    background-color: #ececec;
    padding: $spacing * 0.25 $spacing * 0.75;
    width: 100%;
    height: rem-calc(52);
    box-shadow: none;
    transition: background-color 0.1s;
    &.error {
      border-color: #f00;
    }
    &:focus {
      background-color: #fefefe;
    }
  }
}

// search
.search-form {
  div {
    display: flex;
  }
  label {
    display: none;
  }
  input[type="search"] {
    /*border-radius: 8px 0 0 8px;*/
    flex: 1 1 auto;
    height: auto;
    padding: $spacing * 0.75;
    border-radius: 38px 0 0 38px;
    /*padding-right: $spacing * 2;*/
    /*background: #ececec url("/media/img/bt-cercador-blog.png") no-repeat;
    background-position: 96% 50%;*/
  }
  button {
    /*background-color: #fff;*/
    border: 0;
    border-left-width: 0;
    border-radius: 0 38px 38px 0;
    padding: 0 $spacing * 0.5;
    flex-shrink: 0;
    background: #ececec;
  }
}

// filters
.filters-form {
  fieldset {
    background-color: #ededed;
    border-radius: 8px;
    padding: $spacing * 2 $spacing * 0.75 0;
    position: relative;
  }
  legend {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1rem;
    position: relative;
    top: 2rem;
  }
  button[type="button"] {
    float: right;
    margin-top: -3rem;
    padding: 0;
    border: 0;
    background: transparent url("img/bt-abrir-filtro.svg") no-repeat;
    width: rem-calc(34);
    height: rem-calc(34);
    transition: transform 0.15s ease-in-out;
    &[aria-expanded="true"] {
      transform: rotate(0.5turn);
    }
  }
  ul {
    @include undolist;
    margin-bottom: $spacing;
  }
  input[type="checkbox"] {
    border-color: #fff;
    &:checked + span {
      font-weight: 900;
    }
  }
  label.simple-label {
    font-size: rem-calc(14);
    font-weight: normal;
    span {
      float: right;
      margin-top: 0;
    }
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }
}

// documents
.js .docs-form span.info {
  display: none;
}

// checkboxes & radiobuttons (https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/)
// PENDENT REVISAR I SIMPLIFICAR CODI
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #4a4a4a;
    --active-inner: #249ea8;
    --focus: 3px lightskyblue;
    --border: #bfbfbf;
    --border-hover: #999;
    --background: linear-gradient(to top, #d4d4d4 0%, #f2f2f2 100%);
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;

    -webkit-appearance: none;
    -moz-appearance: none;

    height: $spacing * 1.25;
    width: $spacing * 1.25;
    outline: none;
    display: inline-block;
    // vertical-align: top;
    position: relative;
    margin: 0;
    // cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.1s);
    }
    &:checked {
      // --b: var(--active);
      // --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    // &:disabled {
    //   --b: var(--disabled);
    //   cursor: not-allowed;
    //   opacity: 0.9;
    //   &:checked {
    //     --b: var(--disabled-inner);
    //     --bc: var(--border);
    //   }
    //   & + label {
    //     cursor: not-allowed;
    //   }
    // }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    // &:focus {
    //   box-shadow: 0 0 0 var(--focus);
    // }

    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  input[type="checkbox"] {
    border-radius: 2px;
    &:after {
      width: 8px;
      height: 13px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 10px;
      top: 5px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }
  input[type="radio"] {
    border-radius: 50%;
    &:after {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      // transform: scale(var(--s, 0.7));
      top: 6px;
      left: 7px;
    }
    &:checked {
      --s: 0.5;
      &:after {
        background: var(--active-inner);
        opacity: 1;
      }
    }
  }
}
input[type="checkbox"],
input[type="radio"] {
  margin-right: $spacing * 0.25;
}
input.date {
  background-image: url("img/ico-calendario.svg");
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-position: calc(100% - 0.75rem) 50%;
}

input[type="submit"] {
  background: $primary-color;
  font-family: $font-family-serif;
  font-size: rem-calc(20);
  border-radius: 28px;
}

/*div#gmap {
  height: 410px;
}*/

div#gmap {
 /* height: 300px;*/
  text-align: center;
  font-size: 0.75em;
  line-height: 120%;
  min-height: 300px;
}
div#gmap img {
  max-width: none;
}
