.main-header {
  position: relative;
  margin-top: 0;
  background: #fff;
  @media #{$medium-up} {
    position: relative;
  }
}
.site-logo {
  line-height: 1;
  font-size: rem-calc(16);
  padding: $spacing auto $spacing;
  margin-top: $spacing;
  width: rem-calc(102);
  height: rem-calc(20);
  @media #{$medium-up} {
    margin-bottom: $spacing * 1.75;
    width: auto;
    height: auto;
  }
}

// hack per Safari per evitar aquest problema: https://www.smashingmagazine.com/2018/08/scroll-bouncing-websites/ Seria millor trobar una altra solució però de moment això és el que hi ha.
// _::-webkit-full-page-media,
// _:future,
// :root .main-header.sticky {
//   top: 0;
// }

// headroom
@media #{$large-up} {
  .js {
    .main-header {
      position: fixed;
      z-index: 10;
      right: 0;
      left: 0;
      top: 0;
    }
    .animated {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      will-change: transform, opacity;
    }
    @keyframes slideDown {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0);
      }
    }
    .animated.slideDown {
      animation-name: slideDown;
    }
    @keyframes slideUp {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-100%);
      }
    }
    .animated.slideUp {
      animation-name: slideUp;
    }
  }
}
