/*@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Roboto-Slab.eot);
  src: local('Roboto Slab'), url(../../fonts/Roboto-Slab.eot?#iefix) format('embedded-opentype');
}
*/

@font-face {
  font-family: "Roboto Slab";
  src: url("../../fonts/RobotoSlab-Regular-webfont.eot");
  src: url("../../fonts/RobotoSlab-Regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/RobotoSlab-Regular-webfont.woff") format("woff"),
    url("../../fonts/RobotoSlab-Regular-webfont.ttf") format("truetype"),
    url("../../fonts/RobotoSlab-Regular-webfont.svg#roboto_slabregular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "Roboto Slab Thin";
  src: url("../../fonts/RobotoSlab-Thin-webfont.eot");
  src: url("../../fonts/RobotoSlab-Thin-webfont.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/RobotoSlab-Thin-webfont.woff") format("woff"),
    url("../../fonts/RobotoSlab-Thin-webfont.ttf") format("truetype"),
    url("../../fonts/RobotoSlab-Thin-webfont.svg#roboto_slabthin") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "Roboto Slab Light";
  src: url("../../fonts/RobotoSlab-Light-webfont.eot");
  src: url("../../fonts/RobotoSlab-Light-webfont.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/RobotoSlab-Light-webfont.woff") format("woff"),
    url("../../fonts/RobotoSlab-Light-webfont.ttf") format("truetype"),
    url("../../fonts/RobotoSlab-Light-webfont.svg#roboto_slablight") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "Roboto Slab Bold";
  src: url("../../fonts/RobotoSlab-Bold-webfont.eot");
  src: url("../../fonts/RobotoSlab-Bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/RobotoSlab-Bold-webfont.woff") format("woff"),
    url("../../fonts/RobotoSlab-Bold-webfont.ttf") format("truetype"),
    url("../../fonts/RobotoSlab-Bold-webfont.svg#roboto_slabbold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}
