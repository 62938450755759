// @include typi-init;
html {
  font-size: 100%;
  line-height: 1.5;
}
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
  font-weight: 300;
}
h1,
.t1 {
  font-size: rem-calc(30);
  font-weight: bold;
  line-height: 1.2;
  font-family: $font-family-serif;
  @media #{$medium-up} {
    font-size: rem-calc(42);
  }
  @media #{$large-up} {
    font-size: rem-calc(50);
  }
  article & {
    font-weight: 900;
    line-height: 1.3;
    margin-top: $spacing;
    @media #{$large-up} {
      font-size: rem-calc(32);
    }
  }
  .error & {
    text-transform: uppercase;
    font-weight: 900;
    @media #{$medium-up} {
      font-size: rem-calc(60);
    }
    @media #{$large-up} {
      font-size: rem-calc(80);
    }
  }
  .view-header & {
    margin-top: $spacing;
    @media #{$large-up} {
      font-size: rem-calc(40);
      line-height: 1.15;
    }
    @media #{$xlarge-up} {
      // margin-top: $spacing * 2;
      font-size: rem-calc(44);
    }
  }
}
h2,
.t2 {
  font-family: $font-family-serif;
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1.2;
  @media #{$medium-up} {
    font-size: rem-calc(20);
  }
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
}
h3,
.t3 {
  font-family: $font-family-serif;
  font-size: rem-calc(16);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
}
h4,
.t4 {
  font-family: $font-family-serif;
  font-size: rem-calc(16);
  font-weight: bold;
  line-height: 1.2;
}
h5,
.t5 {
  font-family: $font-family-serif-bold;
  font-size: rem-calc(16);
  font-weight: bold;
  line-height: 1.2;
}
p,
ol,
ul,
dl {
  // font-size: rem-calc(16);
  // line-height: 1.6;
  @media #{$medium-up} {
    font-size: rem-calc(16);
  }
  @media #{$xlarge-up} {
    font-size: rem-calc(16);
  }

  // classes per element contenidor
  .smallest & {
    font-size: rem-calc(12);
  }
  .smaller & {
    font-size: rem-calc(14);
  }
  .small & {
    font-size: rem-calc(16);
  }
  .normal & {
    font-size: rem-calc(18);
  }
  .big & {
    font-size: rem-calc(20);
  }
  .bigger & {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  .biggest & {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }

  // classes directes a l'element de text
  &.smallest {
    font-size: rem-calc(12);
  }
  &.smaller {
    font-size: rem-calc(14);
  }
  &.small {
    font-size: rem-calc(16);
  }
  &.normal {
    font-size: rem-calc(18);
  }
  &.big {
    font-size: rem-calc(20);
  }
  &.bigger {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  &.biggest {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }
}

ol,
dl,
dt,
dd,
figure,
img,
form,
hr,
table {
  margin: 0;
  padding: 0;
  // font-size: rem-calc(15);
}
.main-content {
  ul,
  ol,
  figure {
    @include stack-small;
  }
}
ul {
  //llistat normal
  list-style: none;
  /*font-family: $font-family-serif-thin;*/
  margin-left: $spacing * 0.7;
  li {
    margin: $spacing * 0.2 0;
    position: relative;
    &:before {
      content: "";
      display: block;
      height: 7px;
      width: 7px;
      border-radius: 50%;
      position: absolute;
      left: -19px;
      top: 9px;
      background: $primary-color;
      margin: 0;
    }
    ul {
      margin-top: $spacing * 0.5;
      margin-bottom: $spacing * 0.5;
      @media #{$medium-up} {
        font-size: rem-calc(13);
      }
      p {
        @media #{$medium-up} {
          font-size: rem-calc(13);
        }
      }
      li:before {
        background: #a7b2b4;
        top: 7px;
      }
    }
  }
  &.llista-linies {
    list-style: none;
    margin-left: 0;
    font-family: $font-family-serif-thin;
    li {
      position: relative;
      break-inside: avoid;
      padding: $spacing * 0.55 $spacing $spacing * 0.65 0;
      border-bottom: 1px #d0d0d0 solid;
      &:before {
        display: none;
      }
      ul {
        list-style: none;
        margin-left: $spacing * 1.65;
        margin-top: $spacing * 0.45;
        margin-bottom: 0;
      }
      ul li {
        padding: $spacing * 0.25 $spacing * 0.25 $spacing * 0.25 $spacing * 0.75;
        border: 0;
        position: relative;
        @media #{$medium-up} {
          font-size: rem-calc(13);
        }
        &:before {
          content: "";
          display: block;
          height: 7px;
          width: 7px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 12px;
          background: #a7b2b4;
          margin: 0;
        }
        p {
          @media #{$medium-up} {
            font-size: rem-calc(13);
          }
        }
      }
    }
  }
  &.llista-bold {
    list-style: none;
    li {
      font-family: $font-family-serif-bold;
      ul li {
        font-family: $font-family-serif;
      }
    }
  }
  &.llista-dest {
    list-style: none;
    margin-left: 0;
    li {
      background: #eef0f0;
      border-radius: 27px;
      margin-top: $spacing * 0.65;
      font-family: $font-family-serif;
      padding: $spacing * 0.65 $spacing;
      border: 0;
      &:before {
        display: none;
      }
      & li {
        margin: 0;
        padding: $spacing * 0.25 $spacing * 0.25 $spacing * 0.25 $spacing * 0.75;
        & p {
          @media #{$medium-up} {
            font-size: rem-calc(13);
          }
        }
      }
    }
  }
  &.llista-dest--solid {
    list-style: none;
    margin-left: 0;
    li {
      padding: $spacing * 0.65 $spacing;
      background: $secondary-color;
      color: #fff;
      margin-top: $spacing * 0.65;
      border-radius: 27px;
      font-family: $font-family-serif;
      border: 0;
      &:before {
        display: none;
      }
    }
  }
}

ol {
  margin-left: $spacing;
  li::marker {
    color: $tertiary-color;
    font-weight: bold;
  }
}

// llista simple, sense klanders
ul,
ol {
  &.simple-list {
    list-style: none;
    li {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }
}

// llista amb separadors
/*.borders-list {
  @include undolist;
  li {
    margin-top: 0;
    border-bottom: 1px #d0d0d0 solid;
    /*padding: 0 0 $spacing * 0.5 0;
    &:first-child {
      padding-top: 0;
    }
    /*&:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      margin-top: $spacing * 0.5;
      background: #d0d0d0;
    }
    li {
      border: 0;
    }
  }
  a {
    @include hover;
  }
}
*/

// llista documents relacionats
.rel {
  @include undolist;
  list-style: none;
  li {
    font-family: $font-family-serif;
    margin: $spacing * 0.75 0;
    padding: $spacing * 0.65 $spacing $spacing * 0.65 0;
    border: 0;
    &.download-doc a:after {
      background: url("/media/img/ico-descarrega.png") no-repeat;
    }
    &.external a:after {
      background: url("/media/img/ico-extern.png") no-repeat;
    }
  }
  a {
    position: relative;
    border: 1px black solid;
    padding: $spacing * 0.65 $spacing * 3 $spacing * 0.65 $spacing;
    font-weight: bold;
    width: auto;
    border-radius: 38px;
    color: #000;
    text-decoration: none;
    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
    }
    & .smaller {
      font-size: rem-calc(14);
      margin-left: $spacing * 0.25;
      font-family: $font-family-serif-thin;
    }
    &:after {
      content: "";
      position: absolute;
      right: $spacing;
      vertical-align: middle;
      font-size: 1rem;
    }
    &:hover {
      background: #d0d0d0;
    }
  }
}

// llista gran
ul.list-large {
  @include undolist;
  font-size: rem-calc(20);
  font-weight: bold;
  border-top: 1px solid #979797;
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  @media #{$large-up} {
    font-size: rem-calc(22);
  }
  li {
    padding: $spacing * 0.5 $spacing;
    border-bottom: 1px solid #979797;
    margin-top: 0;
    @media #{$medium-up} {
      padding: $spacing * 0.75 $spacing * 1.5;
    }
    @media #{$large-up} {
      padding: $spacing $spacing * 2;
    }
  }
}

//select múltiple del formulari de houdini
.selection ul li {
  &:before {
    display: none;
  }
}

//llistat amb números
.nums-list,
ol:not(.breadcrumb) {
  counter-reset: item;
  margin-left: 0;
  list-style: none;
  font-family: $font-family-serif-thin;
  > li {
    padding-left: $spacing * 2.5;
    padding-top: $spacing * 0.7;
    padding-bottom: $spacing * 0.7;
    position: relative;
    border-bottom: 1px #979797 solid;
    /*margin-top: 0 !important;*/
    min-height: 75px;
    &:before {
      display: none;
    }
    &:after {
      counter-increment: item;
      content: counter(item, decimal-leading-zero);
      color: $primary-color;
      text-align: center;
      width: $spacing * 2.5;
      height: $spacing * 2.5;
      font-size: rem-calc(40);
      line-height: 1.3;
      position: absolute;
      left: -10px;
      top: 10px;
    }
  }
}

.dl-inline {
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
  dt {
    float: left;
    margin-right: 0.3em;
  }
}

figcaption {
  font-size: rem-calc(12);
}

::selection {
  // color: #fff;
  background-color: #d0d0d0;
  -webkit-text-fill-color: $body-font-color;
}
strong,
b {
  font-weight: 400;
}
// em,
// i {
// }

.upp {
  text-transform: uppercase;
}

// links underline
a {
  color: $link-color;
  .main-content &:not(.btn) {
    @supports (-webkit-background-clip: text) {
      background: linear-gradient(297deg, $grad-end, $grad-start);
      -webkit-background-clip: text;
    }
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    text-decoration-color: $secondary-color;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
    transition-property: text-decoration-color;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
    &:hover {
      text-decoration: none;
      text-decoration-color: transparent;
    }
  }
  // &.external {
  //   main & {
  //     &:after {
  //       content: "";
  //       display: inline-block;
  //       vertical-align: middle;
  //       @include sprite("external-link");
  //       font-size: 1rem;
  //       margin-left: $spacing * 0.25;
  //       opacity: 0.3;
  //       background-color: transparent;
  //     }
  //   }
  // }
}

// link destacat
p.link-dest {
  font-size: rem-calc(22);
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  border: solid #979797;
  border-width: 1px 0;
  padding: $spacing $spacing * 0.5;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media #{$medium-up} {
    font-size: rem-calc(24);
  }
  @media #{$medium-up} {
    font-size: rem-calc(26);
  }
}

// focus
a,
button,
input,
select,
textarea,
[role="button"] {
  &:focus {
    outline: 3px solid $focus-color;
  }
}

.dest-1 {
  @include stack;
  line-height: 1.63;
  padding: $spacing;
  font-family: $font-family-serif;
  position: relative;
  text-align: center;
}
.dest-1::before,
.dest-1::after {
  content: "";
  background: $primary-color;
  display: block;
  height: 4px;
  width: 70%;
  margin: 0 auto $spacing;
}
.dest-1::after {
  margin: $spacing auto 0;
}

.dest-2 {
  @include stack;
  border-radius: 27px;
  /*display: inline-block;*/
  background: $primary-color;
  color: #fff;
  padding: $spacing * 1.5;
  float: left;
  h3 {
    text-align: center;
    text-transform: none;
    font-size: rem-calc(24);
  }
}
/*.dest-3 {
  padding: $spacing;
  text-align: center;
  border-radius: 8px;
  background-image: linear-gradient(291deg, $grad-end, $grad-start);
  font-weight: bold;
  color: #fff;
  a {
    text-decoration: none !important;
    color: #fff !important;
    background: transparent !important;
    -webkit-text-fill-color: inherit !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
}
.dest-4 {
  border: solid #979797;
  border-width: 1px 0;
  padding: $spacing * 0.5 0;
  @media #{$large-up} {
    font-size: rem-calc(18);
  }
  a {
    @include hover;
    color: $primary-color;
    -webkit-text-fill-color: $primary-color !important;
    &:hover {
      text-decoration-color: $primary-color !important;
    }
  }
}*/
p.dest,
.dest p {
  @extend .dest-1;
}

hr {
  border: 0;
  border-top: 1px solid #d9d9d9;
  // margin: $spacing * 2 0 !important;
}
table {
  width: 100%;
  font-size: rem-calc(16);
  font-weight: normal;
  font-family: $font-family-serif;
  th,
  td {
    vertical-align: baseline;
    padding: $spacing * 0.65;
  }
  th {
    font-family: $font-family-serif-bold;
  }
  thead {
    th {
      color: #fff;
      background: #808c8e;
      text-align: left;
      line-height: 1.2;
      &:nth-child(2n) {
        background: $secondary-color;
      }
    }
  }
  tbody {
    td,
    th {
      text-align: left;
      background: #edf0f0;
      /*& th:nth-child(2) {
        background: #e4e7e8;
      }*/
    }
    & td:nth-child(2n) {
      background: #e4e7e8;
    }
    & tr {
      border-bottom: 2px solid #fff;
    }
    /*& tr:nth-child(2) td {
      background: #e4e7e8;
    }*/
    /*& .max-width {
      max-width: 30%;
    }*/
    p {
      margin-top: $spacing * 0.5;
      font-family: $font-family-serif-thin;
    }
  }
  &.table-dest thead th:first-child {
    background: #3d3d3b;
  }
  /*&.table-dest tbody tr:last-child td {
    background: $secondary-color;
  }*/
  &.table-dest tbody tr:last-child td,
  &.table-dest tbody tr:last-child th {
    color: white;
    background: $secondary-color;
    &:nth-child(2n) {
      background: #808c8e;
    }
  }
  &.table-dest thead th:nth-child(2) {
    background: #cc533f;
  }
  &.table-dest tbody td:nth-child(2n) {
    background: #eecbc6;
  }
}
.table-container {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}
.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.inline-list {
  @include undolist;
  font-size: 0;
  margin-left: -$spacing;
  margin-right: -$spacing;
  text-align: center;
  li {
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin: $spacing $spacing 0;
  }
}

// tipus de paràgrafs
p.lead {
  font-weight: bold;
}
.intro {
  strong {
    font-weight: bold;
    position: relative;
    display: inline-block;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      margin-top: 1px;
      background-image: linear-gradient(273deg, $grad-end, $grad-start);
      @media #{$medium-up} {
        height: 4px;
        margin-top: 2px;
      }
      @media #{$large-up} {
        height: 6px;
        margin-top: 3px;
      }
    }
  }
}
.results {
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  strong {
    color: $tertiary-color;
    font-weight: inherit;
    font-size: rem-calc(20);
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
    @media #{$large-up} {
      font-size: rem-calc(26);
    }
    @media #{$xlarge-up} {
      font-size: rem-calc(30);
    }
  }
}

dt {
  font-weight: bold;
}
.info {
  font-weight: normal;
  font-size: rem-calc(14);
  text-transform: uppercase;
  a {
    display: block;
  }
}

blockquote,
p.blockquote {
  background: url("/media/img/ico-cita.png") no-repeat;
  padding-left: $spacing * 1.5;
  &.short {
    width: 100%;
    @media #{$medium-up} {
      width: 60%;
    }
  }
}

.text-bigger {
  font-size: rem-calc(20);
  font-family: $font-family-serif-light;
  strong {
    font-family: $font-family-serif-bold;
  }
}

.author {
  display: flex;
  align-items: center;
  font-family: $body-font-family;
  font-size: rem-calc(14);
  img {
    margin-right: $spacing * 0.5;
  }
  p {
    font-size: rem-calc(14);
  }
}
