.main-footer {
  margin-top: $spacing * 3;
  margin-bottom: $spacing * 2;
  @media #{$medium-up} {
    margin-bottom: 0;
  }
}

.main-footer__container {
  background-color: #f2f2f2;
  text-align: center;
  padding: $spacing * 2 0;
  display: none;
  a {
    text-decoration: none;
  }
  @media #{$medium-up} {
    display: block;
  }
  .column > * {
    margin-top: $spacing;
    margin-bottom: $spacing;
  }
  ul {
    @include undolist;
    font-family: $body-font-family;
    margin-top: $spacing;
    &.social {
      flex-basis: 100%;
      li {
        display: inline;
        margin: 0;
      }
    }
    li {
      padding: $spacing * 0.25;
      border: 0;
    }
  }
 
  @media #{$medium-up} {
    p {
      /*float: left;*/
      margin-right: $spacing;
      &:last-child {
        margin-right: 0;
      }
      font-size: rem-calc(14);
    }
    p,
    ul.sub-menu--1 {
      display: inline-block;
    }
    /*.column {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }*/

    ul:not(.social) {
      flex-basis: 100%;
      font-size: 0;
      margin-top: $spacing;
      /* * + * {
        margin-top: 0;
      }*/
      li {
        border: 0;
        padding: 0;
        display: inline;
        font-size: rem-calc(14);
        &:before {
          content: "|";
          display: inline;
          margin: 0 $spacing * 0.5;
          position: static;
          background: none;
        }
        &:first-child:before {
          display: none;
        }
      }
    }
    address {
      font-style: normal;
    }
  }
  @media #{$large-up} {
    p.bigger {
      border: 1px solid;
      border-width: 0 1px;
    }
    p.text-left {
      flex-basis: 60ch;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
