.equip-list {
  /*position: relative;*/
  p {
    font-family: $body-font-family;
    margin: $spacing * 0.5 0;
    @media #{$medium-up} {
      font-size: rem-calc(12);
    }
  }
  > li {
    border: 0;
    position: relative;
    margin-bottom: $spacing * 3;
    // text-align: center;
    &:before {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: $spacing * 0.5;
      width: $spacing * 3;
      height: 1px;
      background: #000;
    }
    > p {
      @media #{$medium-up} {
        font-size: rem-calc(14);
        /*border: 1px green solid;*/
        margin-right: $spacing * 1.5;
      }
    }
  }
  h2 {
    font-family: $font-family-serif-light;
    strong {
      font-family: $font-family-serif-bold;
    }
  }
  .mesinfo {
    @media #{$medium-up} {
      margin-right: $spacing * 1.5;
    }
    ul {
      @include undolist;
      margin: 0;
      padding: 0;
      display: flex;
      img {
        margin: 0;
      }
      li {
        padding: 0;
        margin: 0 $spacing * 0.5 0 0;
        display: inline-block;
        &.equip-email {
          margin-right: auto;
          a {
            padding-left: $spacing;
            background: url("/media/img/equip-ico-email.png") no-repeat;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          text-decoration: none;
          font-family: $body-font-family;
          &:hover {
            img {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
  /*img {
    margin-bottom: $spacing * 1.5;
  }*/
  @media #{$medium-up} {
    text-align: left;
  }
  .imatge-equip {
    height: 360px;
    margin-bottom: $spacing;
  }
  span {
    display: block;
    max-width: 302px;
    height: 360px;
    transition: all 0.3s ease;
    margin-bottom: $spacing * 1.5;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &.img-1 {
      /*background: url("/media/img/equip/equip-basso-ignasi.png") no-repeat;*/
      opacity: 1;
      &:hover {
        opacity: 0;
      }
    }
    &.alt {
      position: absolute;
      /*background: url("/media/img/equip/equip-basso-ignasi-rollover.png") no-repeat;*/
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }
  .img-equip {
    opacity: 1;
  }
  .img-equip-hover {
    top: -396px;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
}
