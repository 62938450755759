.grid-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  font-weight: normal;
  // justify-content: center;
  @media #{$medium-up} {
    font-size: rem-calc(18);
  }
  li {
    margin: 0;
    display: flex;
    padding: $spacing * 0.25;
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
  }
  a {
    text-decoration: none;
    color: $body-font-color;
    -webkit-text-fill-color: inherit !important;
    display: flex;
    flex-direction: column;
    padding: $spacing;
    width: 100%;
    position: relative;
    line-height: 1.3;
    border-radius: 8px;
    background: #f2f2f2 !important;
    transition: background-color 0.15s ease-out !important;

    // no flexbox fallback
    .ie9 & {
      display: block;
    }
    &:hover,
    &:focus {
      background: darken(#f2f2f2, 5%) !important;
    }
  }

  // fila de 3
  &--3 {
    li {
      @media #{$medium-up} {
        width: 33.33%;
      }
    }
  }

  // fila de 4
  &--4 {
    li {
      @media #{$large-up} {
        width: 25%;
      }
    }
  }

  // caixes amb degradat
  &--color {
    font-weight: 900;
    a {
      @supports (-webkit-text-fill-color: #fff) {
        -webkit-text-fill-color: #fff !important;
      }
      color: #fff;
      background-color: $secondary-color;
      background: linear-gradient(297deg, $grad-end, $grad-start) !important;
      transition: border-radius 0.15s ease-out !important;
      &:hover,
      &:focus {
        background-color: $secondary-color;
        background: linear-gradient(297deg, $grad-end, $grad-start) !important;
        border-radius: 4px;
      }
    }
  }
}

// Classes pel Houdini:

// fila de 2 grises
.grid-list-2-basic {
  @extend .grid-list;
}
// fila de 2 color
.grid-list-2-color {
  @extend .grid-list;
  @extend .grid-list--color;
}
// fila de 3 grises
.grid-list-3-basic {
  @extend .grid-list;
  @extend .grid-list--3;
}
// fila de 3 color
.grid-list-3-color {
  @extend .grid-list;
  @extend .grid-list--3;
  @extend .grid-list--color;
}
// fila de 4 grises
.grid-list-4-basic {
  @extend .grid-list;
  @extend .grid-list--4;
}
// fila de 4 color
.grid-list-4-color {
  @extend .grid-list;
  @extend .grid-list--4;
  @extend .grid-list--color;
}
