.llista-botons {
  display: inline-block;
  list-style: none;
  margin-left: 0;
  li {
    border: 0;
    float: left;
    margin-right: $spacing;
    &:before {
      display: none;
    }
    a {
      font-family: $font-family-serif;
      display: inline-block;
      padding: $spacing * 0.55 $spacing * 2.75;
      text-decoration: none;
      color: #fff;
      border: 0;
      -webkit-text-fill-color: #fff;
      background-color: $primary-color;
      font-size: rem-calc(20);
      line-height: 1.35;
      font-weight: bold;
      text-align: center;
      transition: 0.15s ease-out;
      transition-property: background-color, color;
      border-radius: 38px;
      &:hover,
      &:focus {
        color: #000;
        -webkit-text-fill-color: #000;
        background-color: #edf0f0;
      }
    }

    &-1 {
      background-color: $primary-color;
      padding: $spacing * 1.5 $spacing * 8;
      border-radius: 50px;
      font-size: rem-calc(24);
    }
    &-2 {
      background-color: #cc533f;
      padding: $spacing * 1.5 $spacing * 8;
      border-radius: 50px;
      font-size: rem-calc(24);
    }
    &-3 {
      background-color: #a7b2b4;
      padding: $spacing * 1.5 $spacing * 8;
      border-radius: 50px;
      font-size: rem-calc(24);
      /*&:hover,
    &:focus {
      color: #fff;
      -webkit-text-fill-color: #fff;
      background-color: #a7b2b4;
    }*/
    }
    &-4 {
      background-color: #01284c;
      padding: $spacing * 1.5 $spacing * 8;
      border-radius: 50px;
      font-size: rem-calc(24);
    }
  }
}

button.btn,
a.btn {
  font-family: $font-family-serif;
  display: inline-block;
  padding: $spacing * 0.55 $spacing * 2.75;
  text-decoration: none;
  color: #fff;
  border: 0;
  -webkit-text-fill-color: #fff;
  background-color: $primary-color;
  font-size: rem-calc(20);
  line-height: 1.35;
  font-weight: bold;
  text-align: center;
  transition: 0.15s ease-out;
  transition-property: background-color, color;
  border-radius: 38px;
  &:hover,
  &:focus {
    color: #000;
    -webkit-text-fill-color: #000;
    background-color: #edf0f0;
  }
}

p.btn,
p.btn--transp,
p.btn--solid {
  a {
    font-family: $font-family-serif;
    display: inline-block;
    padding: $spacing * 0.55 $spacing * 2.75;
    text-decoration: none;
    color: #fff;
    border: 0;
    -webkit-text-fill-color: #fff;
    background-color: $primary-color;
    font-size: rem-calc(20);
    line-height: 1.35;
    font-weight: bold;
    text-align: center;
    transition: 0.15s ease-out;
    transition-property: background-color, color;
    border-radius: 38px;
    &:hover,
    &:focus {
      color: #000;
      -webkit-text-fill-color: #000;
      background-color: #edf0f0;
    }
  }
}

p.btn--transp a {
  background-color: transparent;
  border: 1px #000 solid;
  color: #000;
  -webkit-text-fill-color: #000;
}

p.btn--solid a {
  color: #fff;
  -webkit-text-fill-color: #fff;
  background-color: $secondary-color;
  border: 0;
}
 .btn-news {
    display: block !important;
    max-width: 360px;
    margin: 0 auto;
    margin-top: rem-calc(20);
    font-size: rem-calc(16) !important;
    @media #{$medium-up} {
      font-size: rem-calc(18) !important;
    }
  }
