.blog,
.fitxa-blog {
  .new .new-container .wrapper.short .wrapper-inner {
    @media #{$medium-up} {
      width: 55%;
    }
  }
  .author img {
    border-radius: 50%;
  }
  .page-title {
    text-transform: none;
    letter-spacing: normal;
    img {
      border-bottom: 7px $primary-color solid;
      padding-bottom: $spacing;
      margin-bottom: $spacing * 2;
      margin-top: $spacing * 2;
    }
  }
  .list-news {
    // margin-top: $spacing;
    li {
      border: 0;
      margin: 0 0 $spacing 0;
      &:before {
        display: none;
      }
      .category {
        text-transform: uppercase;
        font-family: $body-font-family;
        font-size: rem-calc(14);
        margin-bottom: $spacing * 0.5;
      }
      a {
        text-decoration: none;
        color: #000;
        &:hover {
          text-decoration: underline;
        }
      }
      h3 {
        margin: $spacing * 0.5 0;
        text-transform: none;
        font-size: rem-calc(22);
      }
      .imatge-dest {
        height: 250px;
        display: block;
      }
    }
  }
  //submenú
  .options--blog {
    .first {
      padding-top: 0;
    }
    h2 {
      text-transform: none;
      font-size: rem-calc(26);
      margin-bottom: $spacing;
      &.widget-title {
        letter-spacing: normal;
      }
    }
    > li,
    > section {
      border-bottom: 1px #000 solid;
      margin-bottom: $spacing * 1.5;
      ul {
        list-style: none;
        margin: 0;
        font-family: $body-font-family;
      }
      li {
        font-size: rem-calc(16);
        padding: $spacing * 0.15 0;
        border: 0;
        &:before {
          display: none;
        }
        a {
          text-decoration: none;
          color: #000;
          /*&:hover {
            text-decoration: underline;
          }*/
        }
      }
      &#custom_html-4 li span {
        background: #d8d8d8;
        border-radius: 13px;
        padding: 4px 8px;
      }
      &#archives-2 label {
        display: none;
      }
    }
  }
  article {
    margin: $spacing + 2 0;
    &:first-child {
      margin-top: 0;
    }
  }
  blockquote {
    font-style: normal;
    color: #000;
    line-height: 1.4;
    &:after {
      content: "";
      display: block;
      // position: absolute;
      // bottom: 0;
      // left: 0.75rem;
      width: $spacing * 3;
      height: 1px;
      background: #000;
      margin-top: $spacing;
      margin-left: -2.25rem;
    }
  }
  //paginació
  .nav-links {
    border-top: 1px #ccc solid;
    padding-top: $spacing * 0.5;
    margin-top: $spacing;
    .next {
      .screen-reader-text {
        position: static !important;
        height: auto;
        width: auto;
        clip: auto;
        font-size: rem-calc(16);
      }
    }
    .prev.page-numbers,
    .next.page-numbers {
      background: transparent;
      padding-right: 0;
      padding-left: 0;
    }
    a {
      color: black;
      &:hover {
        color: $primary-color;
      }
    }
  }
  /*.link-more .more-link {
    border: 1px green solid;
  }*/
  .entry-title a {
    margin-left: 0;
    text-decoration: none;
    box-shadow: none;
    &:hover {
      text-decoration: none;
      box-shadow: none;
    }
  }

  h2 {
    font-size: rem-calc(34);
    a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .no-results {
    margin: $spacing * 2 0;
  }
  #main-nav {
    margin-bottom: $spacing;
  }
  .nav-previous {
    @media #{$medium-up} {
      width: 50%;
      float: left;
    }
  }
  .nav-next {
    @media #{$medium-up} {
      width: 50%;
      float: right;
      text-align: right;
    }
  }
}

.fitxa,
.arxiu {
  .category {
    margin-bottom: $spacing * 0.5;
    text-transform: uppercase;
  }
  .author,
  .category {
    a {
      color: black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  p {
    margin: $spacing * 1.5 0;
  }
}

.fitxa-content {
  img {
    margin-top: $spacing;
    margin-bottom: $spacing;
    &.alignleft,
    &.alignright {
      margin-top: 0;
    }
  }
  p,
  iframe {
    margin-bottom: $spacing * 0.5;
    margin-top: $spacing * 0.5;
  }
  ul,
  ol {
    font-family: $font-family-sans-serif;
    clear: both;
  }
}

.arxiu {
  .taxonomy-description {
    font-size: rem-calc(20);
    margin-bottom: $spacing * 3;
    font-family: $font-family-serif-light;
    color: #000;
  }
  p {
    margin-top: 0;
  }
  .author p {
    margin: $spacing * 1.5 0;
  }
  .category {
    margin-top: $spacing * 2;
  }
}

/*.fitxa-blog {
  .category {
    margin-top: 0;
    text-transform: uppercase;
    a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h2 {
    font-size: rem-calc(34);
    margin: $spacing 0 $spacing * 1.5 0;
    a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  p {
    margin: $spacing * 1.5 0;
  }
  .author {
    p {
      margin: 0;
    }
    img {
      border-radius: 50%;
    }
  }

  blockquote {
    width: 100%;
    font-weight: bold;
    @media #{$medium-up} {
      width: 80%;
    }
  }
}*/

@media #{$medium-up} {
  .medium-9.columns.columna--ampla {
    padding-right: $spacing * 3;
  }
}

#comments {
  border-top: 1px #ccc solid;
  padding-top: $spacing * 2;
  h3 {
    text-transform: none;
    font-size: rem-calc(26);
    margin-bottom: $spacing;
  }
  label {
    margin-bottom: $spacing;
  }
  p {
    margin: 0;
  }
}

.widget ul {
  font-weight: normal;
}

// comentaris
.comments-title {
  margin-bottom: $spacing;
}
.comment-respond {
  padding-top: $spacing;
}
.comment-body {
  margin-bottom: 0 !important;
}
.comment-list {
  li::after {
    display: none;
  }
  ol.children {
    margin-top: 1.05rem;
    li:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .comment {
      padding-left: 2.75rem;
    }
    .avatar {
      left: -2.75rem;
    }
  }
}
.comment-author .avatar {
  left: -3.75rem;
}
