.top-heading {
  @media #{$medium-up} {
    padding: $spacing * 1.25 0;
    min-height: rem-calc(117);
    position: relative;
  }
  /*@media screen and (min-width: 99em) {
    background: url("img/simb-itemas.svg") no-repeat;
    background-position: left 50%;
    .error & {
      background: none;
    }
  }*/
  /*&:before {
    @media #{$large-up} {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: #f8f8f8;
      z-index: -1;
      width: 52%;
    }
    @media screen and (min-width: 69em) {
      width: 48%;
    }
    @media #{$xlarge-up} {
      width: 46%;
    }
  }*/
  /*&:after {
    @media #{$medium-up} {
      content: "";
      display: block;
      position: absolute;
      @include scrimGradient("to bottom", #000000);
      opacity: 0.07;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }*/
}
.page-title {
  margin: 0 0 $spacing * 0.5 0;
  font-family: $font-family-serif;
  font-size: rem-calc(28);
  /*letter-spacing: 1.5px;*/
  /*text-align: center;*/
  line-height: 1.2;
  @media #{$medium-up} {
    position: relative;
    z-index: 1;
    // padding-left: $spacing * 1.25;
    max-width: 28ch;
    text-align: left;
    font-size: rem-calc(43);
    /*letter-spacing: 2.25px;*/
  }
  &.bigger {
    font-size: rem-calc(28);
    display: block;
    text-align: left;
    @media #{$medium-up} {
      font-size: rem-calc(50);
    }
  }
  /*&:before {
    @media #{$medium-up} {
      content: "";
      display: block;
      margin-right: $spacing;
      width: 3px;
      height: rem-calc(50);
      background-color: $primary-color;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }*/
}
