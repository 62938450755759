.block-list {
  @include undolist;
  @include block-grid(1);
  /* margin-left: 0 !important;
  margin-right: 0 !important; */
  // margin-bottom: $spacing !important;
  margin-bottom: 0 !important;
  &--2 {
    @media screen and (min-width: 35em) {
      @include block-grid(2);
    }
  }
  &--simple {
    margin-left: -$spacing * 0.5 !important;
    margin-right: -$spacing * 0.5 !important;
    li {
      &::before {
        display: none !important;
      }
    }
  }

  li {
    padding-left: $spacing * 3;
    margin-bottom: $spacing * 2;
    &:before {
      display: block;
    }
    li {
      padding-left: $spacing * 0.65;
      margin-bottom: $spacing * 0.65;
    }
  }
  // a {
  //     &:after {
  //         content: "";
  //         display: block;
  //         position: absolute;
  //         left: 0;
  //         top: 0;
  //         bottom: 0;
  //         width: 100%;
  //     }
  // }
  &__wrapper {
    position: relative;
    > *:last-child {
      margin-bottom: 0;
    }
  }
}

// Estils per l'editor
.block-list-2 {
  @extend .block-list;
  @extend .block-list--2;
}
