.sub-nav {
  @include undolist;
  font-weight: bold;
  font-size: rem-calc(14);
  margin-bottom: $spacing * 1.5;
  @media #{$medium-up} {
    font-size: rem-calc(16);
    position: relative;
    z-index: 1;
  }
  @media #{$large-up} {
    font-size: rem-calc(18);
  }
  > * + * {
    margin-top: 1px;
  }
  a {
    display: block;
    color: $body-font-color;
    text-decoration: none;
    border-bottom: 1px solid #979797;
    padding: $spacing * 0.5 $spacing;
    @media #{$xlarge-up} {
      padding-left: 0;
      padding-right: 0;
    }
    &:hover {
    	color: #fff;
	    border-radius: 25px;
	    border-bottom-color: transparent;
	    @media #{$xlarge-up} {
	      border-radius: 0;
	      position: relative;
	      &:before,
	      &:after {
	        content: "";
	        display: block;
	        position: absolute;
	        height: rem-calc(52);
	        width: rem-calc(52);
	        border-radius: 50%;
	        top: 0;
	        z-index: -1;
	        background-color: inherit;
	      }
	      &:before {
	        left: 0;
	        transform: translateX(-50%);
	      }
	      &:after {
	        right: 0;
	        transform: translateX(50%);
	      }
	    }
    }
  }

  .active  {
  	a {
	    color: #fff;
	    border-radius: 25px;
	    border-bottom-color: transparent;
	    @media #{$xlarge-up} {
	      border-radius: 0;
	      position: relative;
	      &:before,
	      &:after {
	        content: "";
	        display: block;
	        position: absolute;
	        height: rem-calc(52);
	        width: rem-calc(52);
	        border-radius: 50%;
	        top: 0;
	        z-index: -1;
	        background-color: inherit;
	      }
	      &:before {
	        left: 0;
	        transform: translateX(-50%);
	      }
	      &:after {
	        right: 0;
	        transform: translateX(50%);
	      }
	    }
	   }
	    ul {
	    	li {
	    		a {
	    		color: $body-font-color;
	    		}
	    		&:before {
  				content: none;
	  			}
	  			&:after {
	  				content: none;
	  			}
	    	}
	    }
	  
  }
  .active {
  	a {
	    background-color: #303030;
	    &:hover {
	      background-color: #303030;
	    }
    }
    ul {
    	li {
    		a {
    			background-color: transparent;
    		}
    	}
    }
  }

  a:hover {
    background-color: #666;
  }
  .prev,
  .prev2,
  li:last-child a {
    border-bottom-color: transparent;
  }

  &--simple {
    a:hover,
    .active a {
      color: inherit;
      border-radius: 0;
      border-bottom-color: transparent;
      @media #{$xlarge-up} {
        &:before,
        &:after {
          border-radius: 0;
        }
      }
    }
    .active a {
      background-color: #f2f2f2;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    a:hover {
      background-color: #f2f2f2;
    }
  }
   ul {
  	li {
  		a {
  			padding: rem-calc(5 10);
        border-bottom: none;
  			&:hover {
  				color: #fff;
          background-color: #666;
  			}
  			&:before {
  				content: none !important;
  			}
  			&:after {
  				content: none !important;
  			}
  		}
  		&.active {
  			a {
  				color: #fff;
  				background-color: #666;
  			}
  		}
  	}
  }
}
