.section {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
  @media #{$large-up} {
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;
  }
  h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 3.43px;
    text-indent: 3.43px;
  }
  &--grad {
    background-color: #eb4641;
    background-image: linear-gradient(to left, #ff9b95, #eb4641);
    h2 {
      color: #fff;
      text-indent: 4.71px;
      letter-spacing: 4.71px;
      @media #{$medium-up} {
        font-size: rem-calc(22);
      }
    }
  }
  &--bg {
    background-color: #f2f2f2;
  }
}
