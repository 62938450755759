.lang {
  @include undolist;
  font-family: $font-family-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin: $spacing 0 $spacing * 2 0;*/
  @media #{$medium-up} {
    justify-content: flex-end;
    margin-top: 0;
  }
  > li {
    border-right: 1px #636363 solid;
    border-bottom: 0;
    font-size: rem-calc(14);
    margin: 0;
    padding: 0;
    @media #{$medium-up} {
      border: 0;
      margin: 0 $spacing * 0.5;
    }
    &.last {
      border-right: 0;
      @media #{$medium-up} {
        padding-right: 0;
        margin-right: 0;
      }
    }
  }
  a {
    color: #b6b6b6;
    display: block;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    /*width: $spacing * 1.5;*/
    /*height: $spacing * 1.5;*/
    line-height: 2.29;
    padding: 0 $spacing * 0.5;
    margin: 0;
    @media #{$medium-up} {
      padding-right: 0;
    }
    &:hover,
    &.active {
      @media #{$medium-up} {
        color: $body-font-color;
      }
    }
  }
  abbr {
    text-decoration: none;
    border-bottom: 0;
  }
}
