.home {
  h2 {
    font-size: rem-calc(28);
    position: relative;
    @media #{$medium-up} {
      font-size: rem-calc(50);
      padding-top: $spacing;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: $spacing * 3;
        height: 1px;
        background: #000;
      }
    }
    &.smaller {
      font-size: rem-calc(20);
      margin-bottom: $spacing * 2;
    }
  }
  .video {
    margin-top: $spacing;
    @media #{$medium-up} {
      margin-top: 0;
    }
  }
  .author img {
    border-radius: 50%;
  }
}

.slider__caption h2::after {
  display: none;
}
.slick-dots li {
  border: 0;
}
.serveis {
  position: relative;
  clear: both;
  @media #{$medium-up} {
    overflow-x: hidden;
  }
  .row--1 {
    margin-top: $spacing * 1.5;
    @media #{$medium-up} {
      display: flex;
      margin-bottom: $spacing;
    }
  }

  .serveis__wrapper {
    /*background: red;*/
    height: 361px;
    padding: $spacing;
    &--0 {
      background-image: url("/media/img/serveis/consultoria.jpg");
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      background-color: rgba(211, 52, 71, 1);
      background-blend-mode: multiply;
    }
    &--1 {
      background-image: url("/media/img/serveis/comunicacio-interna.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-color: rgba(211, 88, 52, 1);
      background-blend-mode: multiply;
    }
    &--2 {
      background-image: url("/media/img/serveis/projeccio-publica.jpg");
      background-repeat: no-repeat;
      background-position: center;
      filter: grayscale(100%);
    }
    &--3 {
      background: url("/media/img/serveis/elaboracio-de-continguts.jpg") no-repeat;
      background-position: center;
      background-color: rgba(211, 52, 71, 1);
      background-blend-mode: multiply;
      @media #{$medium-up} {
        height: 172px;
        margin-bottom: rem-calc(16);
      }
      @media #{$large-up} {
        width: 200%;
      }
    }
    &--4 {
      background: url("/media/img/serveis/comunicacio-digital.jpg") no-repeat;
      background-position: center;
      filter: grayscale(100%);
      @media #{$medium-up} {
        height: 172px;
      }
      @media #{$large-up} {
        width: 200%;
      }
    }
    &--5 {
      position: relative;
      min-height: rem-calc(361);
      padding: 0;
      &:before {
        content: "";
        background-image: url("/media/img/serveis/grafisme-imatge.jpg");
        background-position: center;
        height: rem-calc(361);
        display: block;
        filter: grayscale(100%);
        @media #{$large-up} {
          position: absolute;
          right: 0px;
          width: 150%;
        }
      }
      h3 {
        position: absolute;
        top: 20px;
        left: 20px;
      }
    }
    &--6 {
      background-image: url("/media/img/serveis/marqueting.jpg");
      background-repeat: no-repeat;
      background-color: rgba(211, 88, 52, 1);
      background-blend-mode: multiply;
    }
    h3 {
      color: #fff;
      text-decoration: none;
      font-family: $font-family-serif-light;
      font-size: rem-calc(20);
      text-transform: none;
      max-width: 220px;
      /*width: auto;*/
      padding: $spacing * 0.5;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  a {
    text-decoration: none;
    &:hover {
      .serveis__wrapper {
        &--0 {
          background-color: rgba(211, 52, 71, 0.8);
        }
        &--1 {
          background-color: rgba(211, 88, 52, 0.8);
        }
        &--2 {
          filter: grayscale(50%);
        }
        &--3 {
          background-color: rgba(211, 52, 71, 0.8);
        }
        &--4 {
          filter: grayscale(50%);
        }
        &--5 {
          &:before {
            filter: grayscale(50%);
          }
        }
        &--6 {
          background-color: rgba(211, 88, 52, 0.8);
        }
      }
    }
  }
}

.new {
  padding-bottom: $spacing;
  margin-bottom: $spacing * 3;
  @media #{$medium-up} {
    /*margin-bottom: $spacing;*/
  }
  .new-container {
    // padding: $spacing * 2.5 $spacing $spacing 0;
    background-color: rgb(179, 179, 179);
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 420px;
    margin-bottom: $spacing;

    .wrapper {
      // @media #{$medium-up} {
      //   width: 50%;
      // }
      position: absolute;
      left: 0;
      bottom: $spacing * 0.5;
      width: 100%;
    }
    a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }
    .wrapper-inner {
      padding: $spacing;
      margin-top: $spacing * 0.5;
      background-color: rgba(255, 255, 255, 0.8);
      @media #{$medium-up} {
        width: 85%;
      }
    }
    .category {
      text-transform: uppercase;
    }
    h3 {
      /*margin: 0 0 $spacing;*/
      font-size: rem-calc(20);
      font-weight: bold;
      padding: $spacing * 0.5 0 0 0;
      text-transform: none;
      @media #{$medium-up} {
        font-size: rem-calc(26);
      }
    }
    p {
      margin-bottom: 0;
    }
    /*.btn {
      // width: 100%;
      margin: 0;
      margin-left: 50%;
      transform: translateX(-50%);
    }*/
  }
}
