.breadcrumb {
  /*display: none;*/
  font-size: rem-calc(22);
  margin-left: 0;
  @media #{$medium-up} {
    display: block;
    margin-bottom: $spacing;
  }
  @media #{$large-up} {
    margin-bottom: $spacing * 1.5;
  }
  > * + *,
  [aria-hidden="true"] {
    margin-left: $spacing * 0.5;
    display: inline !important;
  }
  li {
    font-family: $font-family-serif-thin;
    display: inline;
    margin: 0;
    padding: 0 $spacing * 0.75 0 0;
    position: relative;
    &:after {
      content: " / ";
      position: absolute;
      right: 0;
    }
    &:last-child {
      &:after {
        display: none;
      }
      a {
        color: $body-font-color;
      }
    }
    h1 {
      display: inline;
      font-size: rem-calc(22);
      padding: 0 0 0 $spacing * 0.15;
      font-family: $font-family-serif-bold;
    }
  }
  a {
    @include hover;
  }
}
