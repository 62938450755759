/* Cookie Dialog */
#gdpr-cookie-message {
    position: fixed;
    right: 0;
    bottom: 60px;
    max-width: 450px;
    background-color: #fff;
    opacity: 0.9;
    padding: 30px 35px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
    margin-left: 30px;
    z-index: 9999;
}

#gdpr-cookie-message hr {
    margin: 10px 0;
}

#gdpr-cookie-message h4 {
    color: #1C1C1C;
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 10px;
}

#gdpr-cookie-message h5 {
    color: #d33447;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: none;
}

#gdpr-cookie-message p,
#gdpr-cookie-message ul {
    color: #1C1C1C;
    font-size: 15px;
    line-height: 1.5em;
    margin: 0 0 1.125rem;
}

#gdpr-cookie-message ul li:before {
    display: none;
}

#gdpr-cookie-message p:last-child {
    margin-bottom: 0;
    text-align: right;
}

#gdpr-cookie-message li {
    width: 49%;
    display: inline-block;
    margin-bottom: 1rem;
    padding-left: 0;
}

#gdpr-cookie-message label {
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: inherit !important;
}

#gdpr-cookie-message a {
    color: #d33447 !important;
    text-decoration: none;
    font-size: 15px;
    font-weight: bolder;
    padding-bottom: 2px;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.75);
    transition: all 0.3s ease-in;
}

#gdpr-cookie-message a:hover {
    color: white;
    border-bottom-color: #d33447;
    transition: all 0.3s ease-in;
}

#gpdr-cookie-message a:focus {
    outline: none !important;
}

#gdpr-cookie-message button,
button#ihavecookiesBtn {
    font-size: 14px;
    padding: 7px 20px;
    margin-left: 0;
    cursor: pointer;
    transition: all 0.3s ease-in;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 8px;
}

#gdpr-cookie-message button#gdpr-cookie-accept,
#gdpr-cookie-message button#gdpr-cookie-reject {
    background-color: #d33447;
    color: #FFF;
    border: none;

}

#gdpr-cookie-message button#gdpr-cookie-accept:hover,
#gdpr-cookie-message button#gdpr-cookie-reject:hover {
    transition: all 0.3s ease-in;
    opacity: 0.7;
}

#gdpr-cookie-message button#gdpr-cookie-advanced {
    background: none;
    border: 1px solid #1C1C1C;
    color: #1C1C1C;
}

#gdpr-cookie-message button:disabled {
    opacity: 0.3;
}

#gdpr-cookie-message input[type="checkbox"] {
    float: none;
    margin-top: 0;
    margin-right: 5px;
    background: #e0e0e0;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    opacity: 0.9;
    border: none;
    vertical-align: middle;
    margin-bottom: 0;
}

#gdpr-cookie-message input[type="checkbox"]:focus {
    outline: none !important;
}

#gdpr-cookie-message input[type="checkbox"]:hover {
    background: #1C1C1C;
    cursor: pointer;
    border: 0;
}

#gdpr-cookie-message input[type="checkbox"]:checked {
    background: #1C1C1C;
}

#gdpr-cookie-message input[type="checkbox"]:disabled {
    background: #d33447;
}

#gdpr-cookie-message input[type="checkbox"]:disabled:hover {
    background: #d33447;
    cursor: default !important;
}

#gdpr-cookie-message input[type="checkbox"]:checked:after {
    content: "";
    background: url(img/check-ico-cookies.png) no-repeat 4px 5px;
    width: 24px;
    height: 24px;
    position: relative;
    top: 0px;
    left: 0px;
    display: block;
    background-color: #d33447;
    border-radius: 4px;
    transform:none;
    border:none;
}

@media (max-width: 490px) {

    #gdpr-cookie-message {
        opacity: 1;
    }

    #gdpr-cookie-message li {
        width: 100%;
    }

    #gdpr-cookie-message button {
        float: left;
    }

    .isiOS.isiPhone #gdpr-cookie-message {
        padding: 10px 20px;
    }
}
